.experience-main {
  width: 100%;
}

.basic-experience {
  margin-left: 5%;
  margin-right: 5%;
}

.experience-heading-div {
  display: flex;
}

.experience-heading-div>* {
  flex: 1;
}

.experience-heading-img-div {
  display: flex;
  align-items: center;
  justify-content: center;
}

.experience-heading-text-div {
  text-align: center;
}

.experience-heading-img-div>* {
  max-width: 100%;
  height: auto;
}

.experience-heading-text {
  font-size: 56px;
  font-weight: 400;
  font-family: 'Calibre', 'Inter', 'San Francisco', 'SF Pro Text', -apple-system, system-ui, sans-serif;
  line-height: 1.1;
  text-align: center;
  /* margin-top: 80px; */
}

.experience-heading-sub-text {
  font-size: 30px;
  font-family: 'Calibre', 'Inter', 'San Francisco', 'SF Pro Text', -apple-system, system-ui, sans-serif;
  text-align: center;
  margin-bottom: 10px;
}

.experience-header-detail-text {
  font-size: 20px;
  line-height: 30px;
  margin-top: 0px;
  margin-left: 20px;
  margin-right: 20px;
  font-family: 'Calibre', 'Inter', 'San Francisco', 'SF Pro Text', -apple-system, system-ui, sans-serif;
}

@media (max-width: 1380px) {
  .experience-heading-text {
    font-size: 50px;
    margin-top: 80px;
  }

  .experience-heading-sub-text {
    font-size: 25px;
  }
}

@media (max-width: 768px) {
  .experience-heading-text {
    font-size: 30px;
    margin-top: 20px;
  }

  .experience-heading-sub-text {
    font-size: 20px;
  }

  .experience-heading-div {
    flex-direction: column;
  }

  .experience-header-detail-text {
    font-size: 16px;
    margin-right: 0px;
    line-height: normal;
    text-align: center;
  }
}

.experience-card {
  min-width: 350px;
  /* height: 170px; */
  /* margin-bottom: 20px; */
  border-radius: 10px;
  display: flex;
  flex-direction: column;
  /* padding: 10px; */
  /* margin-left: 10px; */
  -webkit-box-shadow: 0px 6px 11px -2px #a7a9ad57;
  -moz-box-shadow: 0px 6px 11px -2px #a7a9ad57;
  box-shadow: 0px 6px 11px -2px #a7a9ad57;
}

.arrow-left {
  width: 0;
  height: 0;
  margin-top: 40px;
  margin-left: 10px;
  margin-right: -10px;
  border-top: 10px solid transparent;
  border-bottom: 10px solid transparent;
  z-index: 100;
}

/* .experience-card-logo-div {
  margin: 10px;
  margin-top: -10px;
}

.experience-card-stepper {
  display: flex;
  flex-direction: row;
  position: relative;
  align-items: center;
  flex-direction: column;
  margin-top: -40px
} */

.experience-card-body-div {
  width: 100%;
}

.experience-card-header-div {
  display: flex;
  flex-direction: row;
}

.experience-card-logo {
  width: 70px;
  -webkit-box-shadow: 0px 13px 25px -2px #7F8DAA;
  -moz-box-shadow: 0px 13px 25px -2px #7F8DAA;
  box-shadow: 0px 13px 25px -2px #7F8DAA;
  border-radius: 50px;
}

.experience-card-title {
  /* font-family: 'Calibre', 'Inter', 'San Francisco', 'SF Pro Text', -apple-system, system-ui, sans-serif; */
  margin-top: 10px;
  /* margin-bottom: 2px; */
  /* margin-left: 0px; */
  /* margin-right: 7px; */
  text-align: left;
}

.experience-card-company {
  /* font-family: 'Calibre', 'Inter', 'San Francisco', 'SF Pro Text', -apple-system, system-ui, sans-serif; */
  margin: 0;
  text-align: left;
}

.experience-card-duration {
  font-family: 'Calibre', 'Inter', 'San Francisco', 'SF Pro Text', -apple-system, system-ui, sans-serif;
  /* margin-top: 10px; */
  margin-bottom: 2px;
  margin-left: 0px;
  margin-right: 0px;
  text-align: right;
  color: #868e96;
}

.experience-card-heading-left {
  float: left;
  margin-left: 10px;
  margin-right: auto;
}

.experience-card-heading-right {
  float: right;
  margin-left: auto;
  margin-right: 10px;
}

.experience-card-location {
  font-family: 'Calibre', 'Inter', 'San Francisco', 'SF Pro Text', -apple-system, system-ui, sans-serif;
  margin: 0;
  text-align: right;
  color: #868e96;
}

.experience-card-description {
  margin-top: 5px;
  margin-left: 10px;
  text-align: justify;
  margin-right: 20px;
  font-family: 'Calibre', 'Inter', 'San Francisco', 'SF Pro Text', -apple-system, system-ui, sans-serif;
}


.experience-card-company>a {
  position: relative;
  color: #000;
  text-decoration: none;
}

.experience-card-company>a:hover {
  color: #00000000;
}

.experience-card-company>a:hover:before {
  visibility: visible;
  -webkit-transform: scaleX(1);
  transform: scaleX(1);
}

.experience-card-company>a:before {
  content: "";
  position: absolute;
  width: 100%;
  height: 1px;
  bottom: 0;
  left: 0;
  /* background-color: #000; */
  visibility: hidden;
  -webkit-transform: scaleX(0);
  transform: scaleX(0);
  -webkit-transition: all 0.3s ease-in-out 0s;
  transition: all 0.3s ease-in-out 0s;
}

.experience-list-item {
  display: flex;
  align-items: center;
  flex-direction: row;
}

/* .dot:before{
  content:' ';
  position: absolute;
  z-index:2;
  left:0;
  top:0;
  width:20px;
  height:20px; 
  background-color: #ff4200;
  border-radius: 50%;
}

.dot:after {
  content:' ';
  position: absolute;
  z-index:1;
  width:20px;
  height:20px; 
  background-color: #ff4200;
  border-radius: 50%;
  box-shadow: 0 0 10px rgba(0,0,0,.3) inset;
  -webkit-animation-name:'ripple';
  -webkit-animation-duration: 1s;
  -webkit-animation-timing-function: ease;
  -webkit-animation-delay: 0s;
  -webkit-animation-iteration-count: infinite;
  -webkit-animation-direction: normal;
} */

@keyframes ripple {
  0% {
    left: 5px;
    top: 5px;
    opcity: 75;
    width: 0;
    height: 0;
  }

  100% {
    left: -20px;
    top: -20px;
    opacity: 0;
    width: 50px;
    height: 50px;
  }
}

@media (max-width: 768px) {
  .experience-card {
    /* margin-bottom:10px; */
    flex-direction: column;
    text-align: center;
    /* margin-left: 0px; */
  }

  .experience-list-item {
    flex-direction: column;
  }

  .experience-card-logo {
    width: 100px;
  }

  .experience-card-heading-left {
    float: center;
    margin-left: auto;
    margin-right: auto;
  }

  .experience-card-heading-right {
    float: center;
    margin-left: auto;
    margin-right: auto;
  }

  .experience-card-title {
    /* text-align: center; */
  }

  .experience-card-company {
    /* text-align: center; */
  }

  .experience-card-duration {
    /* margin-top: 2px; */
    /* text-align: center; */
  }

  .experience-card-location {
    /* text-align: left; */
    /* text-align: center; */
  }

  .experience-card-header-div {
    flex-direction: column;
  }

  .experience-card-description {
    margin-left: 14px;
    margin-right: 14px;
    text-align: justify;
  }

  .experience-card-header {
    flex-direction: column !important;
  }

  .experience-card-heading-right {
    /* text-align: left; */
    float: left;
  }

  .experience-card-duration {
    text-align: left !important;
  }
}

.minimal {
  font-size: 15px;
}

a:link,
a:visited,
a:hover,
a:active {
  text-decoration: none;
}

.text-align-justify {
  text-align: justify;
}

.custom-card-body {
  border-radius: 8px;
}

.experience-text-gradient {
  background: linear-gradient(150deg, #3b79b3 21.62%, #194a79 50%);
  -webkit-text-fill-color: transparent;
  -webkit-background-clip: text;
  background-clip: text;
  line-height: 1.2;
}