.education-main {
  width: 100%;
}

.basic-education {
  margin-left: 5%;
  margin-right: 5%;
}

.education-heading-div {
  display: flex;
}

.education-heading-div>* {
  flex: 1;
}

.education-heading-img-div {
  display: flex;
  align-items: center;
  justify-content: center;
}

.education-heading-text-div {
  text-align: center;
}

.education-heading-img-div>* {
  max-width: 100%;
  height: auto;
}

.education-heading-text {
  font-size: 56px;
  font-weight: 400;
  font-family: 'Calibre', 'Inter', 'San Francisco', 'SF Pro Text', -apple-system, system-ui, sans-serif;
  line-height: 1.1;
  text-align: center;
  /* margin-top: 80px; */
}

.education-heading-sub-text {
  font-size: 30px;
  font-family: 'Calibre', 'Inter', 'San Francisco', 'SF Pro Text', -apple-system, system-ui, sans-serif;
  text-align: center;
  margin-bottom: 10px;
}

.education-header-detail-text {
  font-size: 20px;
  line-height: 30px;
  margin-top: 0px;
  margin-left: 20px;
  margin-right: 20px;
  font-family: 'Calibre', 'Inter', 'San Francisco', 'SF Pro Text', -apple-system, system-ui, sans-serif;
}

@media (max-width: 1380px) {
  .education-heading-text {
    font-size: 50px;
    margin-top: 80px;
  }

  .education-heading-sub-text {
    font-size: 25px;
  }
}

@media (max-width: 768px) {
  .education-heading-text {
    font-size: 30px;
    margin-top: 20px;
  }

  .education-heading-sub-text {
    font-size: 20px;
  }

  .education-heading-div {
    flex-direction: column;
  }

  .education-header-detail-text {
    font-size: 16px;
    margin-right: 0px;
    line-height: normal;
    text-align: center;
  }
}

.education-card {
  min-width: 350px;
  /* height: 170px; */
  /* margin-bottom: 20px; */
  border-radius: 10px;
  display: flex;
  flex-direction: column;
  /* padding: 10px; */
  /* margin-left: 10px; */
  -webkit-box-shadow: 0px 6px 11px -2px #a7a9ad57;
  -moz-box-shadow: 0px 6px 11px -2px #a7a9ad57;
  box-shadow: 0px 6px 11px -2px #a7a9ad57;
}

.education-card-body-div {
  width: 100%;
}

.education-card-header-div {
  display: flex;
  flex-direction: row;
}

.education-card-logo {
  width: 70px;
  -webkit-box-shadow: 0px 13px 25px -2px #7F8DAA;
  -moz-box-shadow: 0px 13px 25px -2px #7F8DAA;
  box-shadow: 0px 13px 25px -2px #7F8DAA;
  border-radius: 50px;
}

.education-card-title {
  margin-top: 10px;
  text-align: left;
}

.education-card-company {
  margin: 0;
  text-align: left;
}

.education-card-duration {
  font-family: 'Calibre', 'Inter', 'San Francisco', 'SF Pro Text', -apple-system, system-ui, sans-serif;
  /* margin-top: 10px; */
  margin-bottom: 2px;
  margin-left: 0px;
  margin-right: 0px;
  text-align: right;
  color: #868e96;
}

.education-card-heading-left {
  float: left;
  margin-left: 10px;
  margin-right: auto;
}

.education-card-heading-right {
  float: right;
  margin-left: auto;
  margin-right: 10px;
}

.education-card-location {
  font-family: 'Calibre', 'Inter', 'San Francisco', 'SF Pro Text', -apple-system, system-ui, sans-serif;
  margin: 0;
  text-align: right;
  color: #868e96;
}

.education-card-description {
  margin-top: 5px;
  margin-left: 10px;
  text-align: justify;
  margin-right: 20px;
  font-family: 'Calibre', 'Inter', 'San Francisco', 'SF Pro Text', -apple-system, system-ui, sans-serif;
}


.education-card-company>a {
  position: relative;
  color: #000;
  text-decoration: none;
}

.education-card-company>a:hover {
  color: #00000000;
}

.education-card-company>a:hover:before {
  visibility: visible;
  -webkit-transform: scaleX(1);
  transform: scaleX(1);
}

.education-card-company>a:before {
  content: "";
  position: absolute;
  width: 100%;
  height: 1px;
  bottom: 0;
  left: 0;
  visibility: hidden;
  -webkit-transform: scaleX(0);
  transform: scaleX(0);
  -webkit-transition: all 0.3s ease-in-out 0s;
  transition: all 0.3s ease-in-out 0s;
}

.education-list-item {
  display: flex;
  align-items: center;
  flex-direction: row;
}

@keyframes ripple {
  0% {
    left: 5px;
    top: 5px;
    opacity: 75;
    width: 0;
    height: 0;
  }

  100% {
    left: -20px;
    top: -20px;
    opacity: 0;
    width: 50px;
    height: 50px;
  }
}

@media (max-width: 768px) {
  .education-card {
    /* margin-bottom:10px; */
    flex-direction: column;
    text-align: center;
    /* margin-left: 0px; */
  }

  .education-list-item {
    flex-direction: column;
  }

  .education-card-logo {
    width: 100px;
  }

  .education-card-heading-left {
    float: center;
    margin-left: auto;
    margin-right: auto;
  }

  .education-card-heading-right {
    float: center;
    margin-left: auto;
    margin-right: auto;
  }

  .education-card-header-div {
    flex-direction: column;
  }

  .education-card-description {
    margin-left: 14px;
    margin-right: 14px;
    text-align: justify;
  }

  .education-card-header {
    flex-direction: column !important;
  }

  .education-card-heading-right {
    /* text-align: left; */
    float: left;
  }

  .education-card-duration {
    text-align: left !important;
  }
}

.minimal {
  font-size: 15px;
}

a:link,
a:visited,
a:hover,
a:active {
  text-decoration: none;
}

.text-align-justify {
  text-align: justify;
}

.education-text-gradient {
  background: linear-gradient(150deg, #3b79b3 21.62%, #194a79 50%);
  -webkit-text-fill-color: transparent;
  -webkit-background-clip: text;
  background-clip: text;
  line-height: 1.2;
}