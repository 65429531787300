body::-webkit-scrollbar {
  width: 4px;
}

/* Track */
body::-webkit-scrollbar-track {
  background: #f1f1f100;
}

/* Handle */
body::-webkit-scrollbar-thumb {
  background: #8888888f;
  border-radius: 5px;
}

/* Handle on hover */
body::-webkit-scrollbar-thumb:hover {
  background: #555;
}
