/* occupies full height and width of the viewport */
.intro-logo-wrapper {
  height: 100vh;
  width: 100vw;
  overflow: hidden; /*when the logo is growing this hides the scroll bars*/
}

/* centers the logo */
.intro-screen {
  height: 100%;
  display: flex; 
  align-items: center;
  justify-content: center; 
  animation: grow 4s forwards;
} 

@keyframes grow {
  0% {
    transform: scale(0);
  }
  10% {
    transform: scale(1);
    border-radius: 0%;
    height: 100%;
    width: 100%;
  }

  90% {
    transform: scale(1.2);
    border-radius: 0%;
    height: 100%;
    width: 100%;
  }

  100% {
    transform: scale(0);
    transform-origin: 50% 50%;
  }
}
