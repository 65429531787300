.text-align-justify {
  text-align: justify;
}

.skill-logo-inline {
  display: inline-block;
}

.image-attribution {
  color: rgba(127, 141, 170, 0.69);
}

.card {
  background-color: transparent;
  /* background: linear-gradient(214.25deg, hsla(0, 0%, 100%, .6) -60.4%, hsla(0, 0%, 100%, .2) 130.64%);
  backdrop-filter: blur(10px); */
  -webkit-box-shadow: 0px 6px 11px -2px #a7a9ad57;
    -moz-box-shadow: 0px 6px 11px -2px #a7a9ad57;
    box-shadow: 0px 6px 11px -2px #a7a9ad57;
    border: 0px;
}

.skill-card {
  border-radius: 5px;
}