.card {
  border-width: 0;
  box-shadow: 0 0.375rem 1.5rem 0 rgba(140, 152, 164, .125);
}

.card {
  --bs-card-spacer-y: 2rem;
  --bs-card-spacer-x: 2rem;
  --bs-card-title-spacer-y: 0.25rem;
  --bs-card-border-width: 0.0625rem;
  --bs-card-border-color: rgba(33, 50, 91, 0.1);
  --bs-card-border-radius: 0.5rem;
  --bs-card-box-shadow: 0rem 0.375rem 1.5rem 0rem rgba(140, 152, 164, 0.125);
  --bs-card-inner-border-radius: 0.4375rem;
  --bs-card-cap-padding-y: 2rem;
  --bs-card-cap-padding-x: 2rem;
  --bs-card-cap-bg: transparent;
  --bs-card-bg: #fff;
  --bs-card-img-overlay-padding: 2rem 2rem;
  --bs-card-group-margin: 0.75rem;
  position: relative;
  display: flex;
  flex-direction: column;
  min-width: 0;
  height: var(--bs-card-height);
  word-wrap: break-word;
  background-color: var(--bs-card-bg);
  background-clip: border-box;
  border: var(--bs-card-border-width) solid var(--bs-card-border-color);
  border-radius: var(--bs-card-border-radius);
}

.card-img {
  width: 100%;
  height: 100%;
  object-fit: cover;
}

.p-2 {
  padding: 0.5rem !important;
}