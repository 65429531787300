.main-text {
  margin-top: 0px;
  line-height: 1.5;
  font-family: 'Calibre', 'Inter', 'San Francisco', 'SF Pro Text', -apple-system, system-ui, sans-serif;
  background: linear-gradient(170deg, #3a9ade 31.62%, #0a5f9c 80%);
  -webkit-text-fill-color: transparent;
  -webkit-background-clip: text;
  background-clip: text;
}

.main-title {
  background-image: url("../../assets/svg/blobanimation.svg");
  background-repeat: no-repeat, no-repeat;
  background-size: auto 100%;
}

.skills-title {
  background: linear-gradient(180deg, #c0c9cc 31.62%, #7c7c82 80%);
  -webkit-text-fill-color: transparent;
  -webkit-background-clip: text;
  background-clip: text;
  line-height: 1.5;
}

.zindex {
  z-index: -100;
}

/* 
.card {
  border-width: 0;
  box-shadow: 0 0.375rem 1.5rem 0 rgba(140, 152, 164, .125);
}

.card {
  --bs-card-spacer-y: 2rem;
  --bs-card-spacer-x: 2rem;
  --bs-card-title-spacer-y: 0.25rem;
  --bs-card-border-width: 0.0625rem;
  --bs-card-border-color: rgba(33, 50, 91, 0.1);
  --bs-card-border-radius: 0.5rem;
  --bs-card-box-shadow: 0rem 0.375rem 1.5rem 0rem rgba(140, 152, 164, 0.125);
  --bs-card-inner-border-radius: 0.4375rem;
  --bs-card-cap-padding-y: 2rem;
  --bs-card-cap-padding-x: 2rem;
  --bs-card-cap-bg: transparent;
  --bs-card-bg: #fff;
  --bs-card-img-overlay-padding: 2rem 2rem;
  --bs-card-group-margin: 0.75rem;
  position: relative;
  display: flex;
  flex-direction: column;
  min-width: 0;
  height: var(--bs-card-height);
  word-wrap: break-word;
  background-color: var(--bs-card-bg);
  background-clip: border-box;
  border: var(--bs-card-border-width) solid var(--bs-card-border-color);
  border-radius: var(--bs-card-border-radius);
}

.card-img {
  width: 100%;
  height: 100%;
  object-fit: cover;
} */

.react-rotating-text-cursor {
  animation: blinking-cursor 0.8s cubic-bezier(0.68, 0.01, 0.01, 0.99) 0s infinite;
}

*,
::after,
::before {
  box-sizing: border-box;
}

/* .p-2 {
  padding: 0.5rem !important;
} */


@keyframes blinking-cursor {
  0% {
    opacity: 0;
  }

  50% {
    opacity: 1;
  }

  100% {
    opacity: 0;
  }
}

div.sticky {
  position: -webkit-sticky;
  position: sticky;
  top: 55px;
  z-index: -100;
}

div.sticky-skills {
  position: -webkit-sticky;
  position: sticky;
  top: 0px;
  z-index: -100;
}


.bg-blur {
  background: linear-gradient(214.25deg, hsla(0, 0%, 100%, .6) -60.4%, hsla(0, 0%, 100%, .2) 130.64%);
  backdrop-filter: blur(10px);
}

.card-body,.custom-card-body {
  /* background-color: transparent; */
  background: linear-gradient(214.25deg, hsla(0, 0%, 100%, 0.669) -60.4%, hsla(0, 0%, 100%, 0.459) 130.64%);
  backdrop-filter: blur(10px);
}

.custom-font {
  font-family: 'Inconsolata', monospace;
    font-family: 'Poppins', sans-serif;
}